import React, { FC, useEffect } from 'react'
import classNames from 'classnames'

const Popup: FC<{ children?: React.ReactNode, classNameWindow?: string }> = ({children, classNameWindow}) => {
  return (
    <div className="fixed bottom-0 left-0 z-10 w-screen h-[calc(100vh_-_80px)] flex items-center justify-center z-20 bg-[#43434378] p-2.5">
      <div className={
        classNames(
          'max-w-[1000px] max-h-[90%] flex flex-col px-5 py-7 lg:p-10 bg-white rounded-[30px] shadow-shadowPopup relative',
          classNameWindow
        )
      }>
        {children}
      </div>
    </div>
  )
}

export default Popup