import React, { useState, useEffect, useRef, FC, InputHTMLAttributes } from 'react';
import { isValid } from './validate';
import classNames from 'classnames';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  disabled?: boolean;
  onTimeChange: (a: string) => void;
  // type:string;   NEED?
  onFocusHandler?: (e: React.FocusEvent<HTMLInputElement>) => void;
  placeholder?: string;
  className?: string;
  label?: string;
  classNameWrap?: string;
  classNameLabel?: string;
  error?: string;
  showError?: boolean;
  onBlurHandler?: (e: React.FocusEvent<HTMLInputElement>) => void;
  value: string;
}

export const TimePickerCustom: FC<IProps> = ({
  disabled,
  onTimeChange,
  // type,
  onFocusHandler,
  placeholder = '',
  className,
  onBlurHandler,
  error,
  showError = true,
  classNameLabel,
  classNameWrap,
  label,
  required,
  value,
}) => {
  const [time, setTime] = useState<string | null>(null);

  let lastVal = '';
  useEffect(() => {
    if (value) {
      setTime(value);
    }
  }, [value]);

  const onChangeHandler = (val: string) => {
    if (isValid(val)) {
      if (val && !val.includes('0') && Number(val) > 2 && Number(val) < 10) {
        val = '0' + val;
      }
      if (val.length === 2 && lastVal.length !== 3 && val.indexOf(':') === -1) {
        val = val + ':';
      }

      if (val.length === 2 && lastVal.length === 3) {
        val = val.slice(0, 1);
      }

      if (val.length > 5) {
        return false;
      }

      lastVal = val;

      setTime(val);

      if (val.length === 5) {
        onTimeChange(val);
      }
    }
  };

  return (
    <div
      className={classNames(
        'w-full h-[40px] md:h-[48px] relative flex flex-col gap-[5px]',
        classNameWrap,
        {
          '!h-auto': label,
        }
      )}>
      {label && (
        <div className={classNames(classNameLabel)}>
          {label}
          {required ? ' *' : ''}
        </div>
      )}
      <div className="relative">
        <input
          className={classNames(
            'w-full h-[48px] p-[10px_15px] md:p-[13px_20px] border border-greyBase focus:border-2 rounded-[10px] text-blackBase !pr-[44px]',
            className,
            {
              'bg-greyBase15 !border-none': disabled,
              '!border-redBase': error,
            }
          )}
          disabled={disabled}
          placeholder={placeholder}
          value={time || undefined}
          onChange={(e) => onChangeHandler(e.target.value)}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
        />

        <span className="absolute top-[50%] right-[20px] -translate-y-1/2">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M10 5V10H15" stroke="#111026" strokeLinecap="round" strokeLinejoin="round" />
            <path
              d="M10.0013 18.3332C14.6038 18.3332 18.3346 14.6023 18.3346 9.99984C18.3346 5.39734 14.6038 1.6665 10.0013 1.6665C5.3988 1.6665 1.66797 5.39734 1.66797 9.99984C1.66797 14.6023 5.3988 18.3332 10.0013 18.3332Z"
              stroke="#111026"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </div>
      <div className="text-sm text-redBase absolute top-[110%]">{showError && error}</div>
    </div>
  );
};
