import classNames from 'classnames';
import { FC, useEffect, useRef, useState } from 'react';
import Input from '../input';
import { Arrow } from '../../icons/arrow';
import { HelperIcon } from '../../icons/helper';

type SelectProps = {
  label?: string;
  items: string[];
  className?: string;
  placeholder?: string;
  creatble?: boolean;
  onChange?: (a: string) => void;
  selected?: string;
  onCreate?: () => void;
  error?: string;
  showError?: boolean;
  required?: boolean;
  helper?: string;
};

export const CustomSelect: FC<SelectProps> = ({
  label,
  items,
  className,
  placeholder,
  creatble,
  onChange,
  onCreate,
  selected,
  error = '',
  showError = true,
  required = false,
  helper = '',
}) => {
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const [searchVal, setSearchVal] = useState('');

  const handleChange = (val: string) => {
    setSearchVal('');
    onChange && onChange(val);
    setOpen(false);
  };

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [open]);
  return (
    <div className={classNames('w-full flex flex-col gap-[5px] relative', className)} ref={ref}>
      <div className="flex items-center gap-[10px]">
        {label && (
          <label className="text-xs text-[#585858] whitespace-nowrap">
            {label} {required ? ' *' : ''}
          </label>
        )}
        {helper && (
          <div className="relative  w-full max-w-[234px]">
            <span
              className="w-[15px] block"
              onMouseMove={() => setHover(true)}
              onMouseLeave={() => setHover(false)}>
              <HelperIcon />
            </span>

            {hover && (
              <div className="absolute z-[10] w-full rounded-[6px] bg-white text-xs text-blackBase -top-20 p-3 drop-shadow-md">
                {helper}
              </div>
            )}
          </div>
        )}
      </div>

      <Input
        classNameWrap={open ? 'z-[11]' : ''}
        placeholder={placeholder}
        value={selected || searchVal || undefined}
        onChange={(e) => {
          if (selected !== '') {
            onChange && onChange('');
          }
          setSearchVal(e.target.value);
        }}
        onFocus={() => setOpen(true)}
        icon={{
          type: 'suffix',
          icon: (
            <span onClick={() => setOpen(!open)}>
              <Arrow
                className={classNames('transition-all cursor-pointer', {
                  ['rotate-180']: open,
                })}
              />
            </span>
          ),
        }}
        error={error}
        showError={false}
      />
      {open && (
        <ul className="w-full h-[300px] z-10 absolute top-[calc(100%_-_10px)] flex flex-col pt-5 pb-[15px] px-5 bg-[#FFFFFF] border list-none border-[#585858] rounded-[0px_0px_10px_10px] max-h-[360px]  overflow-y-scroll no-scrollbar">
          {creatble && (
            <li
              className="w-full  cursor-pointer text-redBase break-words flex items-center gap-2.5 p-[12px] hover:!bg-redBase group hover:!text-white hover:rounded-[6px] border-b border-[#585858]"
              onClick={() => onCreate && onCreate()}>
              <svg
                className="stroke-redBase group-hover:stroke-white"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8 0.928711V15.0708M0.928932 7.99978H15.0711"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Создать клиента
            </li>
          )}
          {items
            .filter((el) => (searchVal ? el.toLowerCase().includes(searchVal.toLowerCase()) : el))
            .map((el, idx) => (
              <li
                onClick={() => handleChange(el)}
                key={idx}
                className={classNames(
                  'w-full  border-b cursor-pointer hover:bg-[#DEC05E] hover:text-[#776743] hover:rounded-[6px] border-[#585858]  p-[12px] break-words last:border-none',
                  {
                    'bg-[#DEC05E] rounded-[6px] text-[#776743]': selected === el,
                  }
                )}>
                {el}
              </li>
            ))}
        </ul>
      )}
      <div className="text-sm text-redBase absolute top-[110%]">{showError && error}</div>
    </div>
  );
};
