import React, { FC, MutableRefObject, ReactHTMLElement, SyntheticEvent, useEffect, useRef, useState } from 'react'
import Checkbox from '../checkbox'
import Popup from '../popup'
import { useChangeUserMutation } from '../../../services/users.api'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { selectUser, setUserData } from '../../../store/auth.slice'
import { toast } from 'react-toastify'
import { CloseCrossBtn } from '../../icons/close-cross-btn'
import classNames from 'classnames'

const ConfirmCodex: FC<{closeCodex: () => void}> = ({closeCodex}) => {
  const userData = useAppSelector(selectUser)
  const [agreeWithCodex, setAgreeWithCodex] = useState(false)
  const [errorAgreeWithCodex, setErrorAgreeWithCodex] = useState(false)
  const [isActiveBtn, setIsActiveBtn] = useState(false)
  const [changeUser] = useChangeUserMutation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (userData) {
      setAgreeWithCodex(userData.agree_with_code)
    }
  }, [userData])

  const onAgreeCodex = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAgreeWithCodex(e.target.checked)
    setErrorAgreeWithCodex(!e.target.checked)
  }

  const onConfirmCodex = async () => {
    if (!agreeWithCodex) {
      setErrorAgreeWithCodex(!agreeWithCodex)
      return
    }

    try {
      if (userData !== null) {
        const res = await changeUser({
          id: userData.id,
          agree_with_code: agreeWithCodex
        })

        // @ts-ignore
        if (res?.error?.status === 404) {
          toast.error('Произошла ошибка')
          return
        }
        dispatch(setUserData(res?.data || userData))
        closeCodex()
      }
    } catch (e) {
      console.log(e)
    }
  }

  const onScroll = (e: SyntheticEvent) => {
    const target = e.target as HTMLTextAreaElement;
    if (Math.ceil(target.offsetHeight + target.scrollTop + 100) >= target.scrollHeight) {
      setIsActiveBtn(true)
    }
  }

  return (
    <Popup>
      {
        userData?.agree_with_code && (
          <div className="absolute top-[30px] right-[30px] cursor-pointer" onClick={closeCodex}>
            <CloseCrossBtn />
          </div>
        )
      }

      <h2 className="font-comfortaa font-bold text-blackBase text-lg lg:text-2xl mb-5">Этический кодекс психолога</h2>
      <div className="flex flex-col max-h-[70vh] overflow-y-scroll mb-[5px]" onScroll={onScroll}>
        <div className="flex flex-col gap-5 text-[13px] sm:text-sm">
          <ul className="text-[13px] lg:text-[14px] text-greyBase list-decimal pl-5 lg:pl-10">
            <li>
              <p>
                Этический кодекс психолога Российского психологического общества составлен в соответствии с Конституцией Российской Федерации, Федеральным законом Российской Федерации № 152-ФЗ от 27 июля 2006 года «О персональных данных», Уставом Российского психологического общества, Всеобщей декларацией прав человека, Хельсинкской декларацией Всемирной медицинской ассоциации «Этические принципы проведения медицинских исследований с участием людей в качестве субъектов исследования», международной Универсальной декларацией этических принципов для психологов, Этическим метакодексом Европейской федерации психологических ассоциаций.
              </p>
            </li>
            <li>
              <p>
                Консультативным и регулирующим органом Российского психологического общества по вопросам профессиональной этики психолога является Этический комитет Российского психологического общества.
              </p>
            </li>
            <li>
              <p>
                В настоящем Этическом кодексе термин «Психолог» относится к лицу, имеющему высшее психологическое образование.
              </p>
            </li>
            <li>
              <p>
                В настоящем Этическом кодексе термин «Клиент» относится к лицу, группе лиц или организации, которые согласились быть объектом психологических исследований в личных, научных, производственных или социальных интересах или лично обратились к Психологу за психологической помощью.
              </p>
            </li>
            <li>
              <p>
                Действие данного Этического кодекса распространяется на все виды деятельности психологов, определенные настоящим Этическим кодексом. Действие данного Этического кодекса распространяется на все формы работы Психолога, в том числе осуществляемые дистанционно или посредством сети Интернет.
              </p>
            </li>
            <li>
              <p>
                Профессиональная деятельность психолога характеризуется его особой ответственностью перед клиентами, обществом и психологической наукой, и основана на доверии общества, которое может быть достигнуто только при соблюдении этических принципов профессиональной деятельности и поведения, содержащихся в настоящем Этическом кодексе.
              </p>
            </li>
            <li>
              <p>
                Этический кодекс психологов служит: для внутренней регуляции деятельности сообщества психологов; для регуляции отношений психологов с обществом; основой применения санкций при нарушении этических принципов профессиональной деятельности.
              </p>
            </li>
          </ul>

          <h2 className="text-blackBase lg:text-lg font-bold">I. Этические принципы психолога</h2>
          <p className="text-[13px] lg:text-[14px] text-greyBase">
            Этика работы психолога основывается на общечеловеческих моральных и нравственных ценностях. Идеалы свободного и всестороннего развития личности и ее уважения, сближения людей, создания справедливого, гуманного, процветающего общества являются определяющими для деятельности психолога. Этические принципы и правила работы психолога формулируют условия, при которых сохраняются и упрочиваются его профессионализм, гуманность его действий, уважение людей, с которыми он работает, и при которых усилия психолога приносят реальную пользу.
          </p>
          <ul className="text-[13px] lg:text-[14px] text-greyBase list-decimal pl-5 lg:pl-10">
            <li>
              <p>Принцип уважения</p>
              <p>Психолог исходит из уважения личного достоинства, прав и свобод человека, провозглашенных и гарантированных Конституцией Российской Федерации и международными документами о правах человека.</p>
              <p>Принцип уважения включает:</p>
              <ul className="list-decimal pl-5 lg:pl-10">
                <li>
                  <p>Уважение достоинства, прав и свобод личности</p>
                  <ul className="list-decimal pl-5 lg:pl-10">
                    <li>
                      <p>Психолог с равным уважением относится к людям вне зависимости от их возраста, пола, сексуальной ориентации, национальности, принадлежности к определенной культуре, этносу и расе, вероисповедания, языка, социально-экономического статуса, физических возможностей и других оснований.</p>
                    </li>
                    <li>
                      <p>Беспристрастность Психолога не допускает предвзятого отношения к Клиенту. Все действия Психолога относительно Клиента должны основываться на данных, полученных научными методами. Субъективное впечатление, которое возникает у Психолога при общении с Клиентом, а также социальное положение Клиента не должны оказывать никакого влияния на выводы и действия Психолога.</p>
                    </li>
                    <li>
                      <p>Психолог избегает деятельности, которая может привести к дискриминации Клиента по любым основаниям.</p>
                    </li>
                    <li>
                      <p>Психологу следует так организовать свою работу, чтобы ни ее процесс, ни ее результаты не наносили вреда здоровью и социальному положению Клиента и связанных с ним лиц.</p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>Конфиденциальность</p>
                  <ul className="list-decimal pl-5 lg:pl-10">
                    <li>
                      <p>Информация, полученная Психологом в процессе работы с Клиентом на основе доверительных отношений, не подлежит намеренному или случайному разглашению вне согласованных условий.</p>
                    </li>
                    <li>
                      <p>Результаты исследования должны быть представлены таким образом, чтобы они не могли скомпрометировать Клиента, Психолога или психологическую науку.</p>
                    </li>
                    <li>
                      <p>Психодиагностические данные студентов, полученные при их обучении, должны рассматриваться конфиденциально. Сведения о Клиентах также должны рассматриваться конфиденциально.</p>
                    </li>
                    <li>
                      <p>Демонстрируя конкретные случаи своей работы, Психолог должен обеспечить защиту достоинства и благополучия Клиента.</p>
                    </li>
                    <li>
                      <p>Психолог не должен отыскивать о Клиенте информацию, которая выходит за рамки профессиональных задач Психолога.</p>
                    </li>
                    <li>
                      <p>Клиент имеет право на консультацию Психолога или работу с ним без присутствия третьих лиц.</p>
                    </li>
                    <li>
                      <p>Неконтролируемое хранение данных, полученных при исследованиях, может нанести вред Клиенту, Психологу и обществу в целом. Порядок обращения с полученными в исследованиях данными и порядок их хранения должны быть жестко регламентированы.</p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>Осведомленность и добровольное согласие Клиента</p>
                  <ul className="list-decimal pl-5 lg:pl-10">
                    <li>
                      <p>Клиент должен быть извещен о цели работы, о применяемых методах и способах использования полученной информации. Работа с Клиентом допускается только после того, как Клиент дал информированное согласие в ней участвовать. В случае, если Клиент не в состоянии сам принимать решение о своем участии в работе, такое решение должно быть принято его законными представителями.</p>
                    </li>
                    <li>
                      <p>Психолог должен сообщать Клиенту обо всех основных шагах или лечебных действиях. В случае стационарного лечения Психолог должен информировать Клиента о возможных рисках и об альтернативных методах лечения, включая непсихологические.</p>
                    </li>
                    <li>
                      <p>Видео- или аудиозаписи консультации или лечения Психолог может делать только после того, как получит согласие на это со стороны Клиента. Это положение распространяется и на телефонные переговоры. Ознакомление третьих лиц с видео-, аудиозаписями консультации и телефонными переговорами Психолог может разрешить только после получения согласия на это со стороны Клиента.</p>
                    </li>
                    <li>
                      <p>Участие в психологических экспериментах и исследованиях должно быть добровольным. Клиент должен быть проинформирован в понятной для него форме о целях, особенностях исследования и возможном риске, дискомфорте или нежелательных последствиях, чтобы он мог самостоятельно принять решение о сотрудничестве с Психологом. Психолог обязан предварительно удостовериться в том, что достоинство и личность Клиента не пострадают. Психолог должен принять все необходимые предосторожности для обеспечения безопасности и благополучия Клиента и сведения к минимуму возможности непредвиденного риска.</p>
                    </li>
                    <li>
                      <p>В тех случаях, когда предварительное исчерпывающее раскрытие информации противоречит задачам проводимого исследования, Психолог должен принять специальные меры предосторожности для обеспечения благополучия испытуемых. В тех случаях, когда это возможно, и при условии, что сообщаемая информация не нанесет вреда Клиенту, все разъяснения должны быть сделаны после окончания эксперимента.</p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>Самоопределение Клиента</p>
                  <ul className="list-decimal pl-5 lg:pl-10">
                    <li>
                      <p>Психолог признает право Клиента на сохранение максимальной автономии и самоопределения, включая общее право вступать в профессиональные отношения с психологом и прекращать их.</p>
                    </li>
                    <li>
                      <p>Клиентом может быть любой человек в случае своей несомненной дееспособности по возрасту, состоянию здоровья, умственному развитию, физической независимости. В случае недостаточной дееспособности человека решение о его сотрудничестве с Психологом принимает лицо, представляющее интересы этого человека по закону.</p>
                    </li>
                    <li>
                      <p>Психолог не должен препятствовать желанию Клиента привлечь для консультации другого психолога (в тех случаях, когда к этому нет юридических противопоказаний).</p>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <p>Принцип компетентности</p>
              <p>Психолог должен стремиться обеспечивать и поддерживать высокий уровень компетентности в своей работе, а также признавать границы своей компетентности и своего опыта. Психолог должен предоставлять только те услуги и использовать только те методы, которым обучался и в которых имеет опыт.</p>
              <p>Принцип компетентности включает:</p>
              <ul className="list-decimal pl-5 lg:pl-10">
                <li>
                  <p>Знание профессиональной этики</p>
                  <ul className="list-decimal pl-5 lg:pl-10">
                    <li>
                      <p>Психолог должен обладать исчерпывающими знаниями в области профессиональной этики и обязан знать положения настоящего Этического кодекса. В своей работе Психолог должен руководствоваться этическими принципами.</p>
                    </li>
                    <li>
                      <p>Если персонал или студенты выступают в качестве экспериментаторов в проведении психодиагностических процедур, Психолог должен обеспечить, независимо от их собственной ответственности, соответствие совершаемых ими действий профессиональным требованиям.</p>
                    </li>
                    <li>
                      <p>Психолог несет ответственность за соответствие профессионального уровня персонала, которым он руководит, требованиям выполняемой работы и настоящего Этического кодекса.</p>
                    </li>
                    <li>
                      <p>В своих рабочих контактах с представителями других профессий Психолог должен проявлять лояльность, терпимость и готовность помочь.</p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>Ограничения профессиональной компетентности</p>
                  <ul className="list-decimal pl-5 lg:pl-10">
                    <li>
                      <p>Психолог обязан осуществлять практическую деятельность в рамках собственной компетентности, основанной на полученном образовании и опыте.</p>
                    </li>
                    <li>
                      <p>Только Психолог осуществляет непосредственную (анкетирование, интервьюирование, тестирование, электрофизиологическое исследование, психотерапия, тренинг и др.) или опосредованную (биографический метод, метод наблюдения, изучение продуктов деятельности Клиента и др.) работу с Клиентом.</p>
                    </li>
                    <li>
                      <p>Психолог должен владеть методами психодиагностической беседы, наблюдения, психолого-педагогического воздействия на уровне, достаточном, чтобы поддерживать у Клиента чувство симпатии, доверия и удовлетворения от общения с Психологом.</p>
                    </li>
                    <li>
                      <p>Если Клиент болен, то работа с ним допустима только с разрешения врача или согласия других лиц, представляющих интересы Клиента.</p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>Ограничения применяемых средств</p>
                  <ul className="list-decimal pl-5 lg:pl-10">
                    <li>
                      <p>Психолог может применять методики, которые адекватны целям проводимого исследования, возрасту, полу, образованию, состоянию Клиента, условиям эксперимента. Психодиагностические методики, кроме этого, обязательно должны быть стандартизованными, нормализованными, надежными, валидными и адаптированными к контингенту испытуемых.</p>
                    </li>
                    <li>
                      <p>Психолог должен применять методы обработки и интерпретации данных, получившие научное признание. Выбор методов не должен определяться научными пристрастиями Психолога, его общественными увлечениями, личными симпатиями к Клиентам определенного типа, социального положения или профессиональной деятельности.</p>
                    </li>
                    <li>
                      <p>Психологу запрещается представлять в результатах исследования намеренно искаженные первичные данные, заведомо ложную и некорректную информацию. В случае обнаружения Психологом существенной ошибки в своем исследовании после того, как исследование было опубликовано, он должен предпринять все возможные действия по исправлению ошибки и дальнейшему опубликованию исправлений.</p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>Профессиональное развитие</p>
                  <ul className="list-decimal pl-5 lg:pl-10">
                    <li>
                      <p>Психолог должен постоянно повышать уровень своей профессиональной компетентности и свою осведомленность в области этики психологической работы (исследования).</p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>Невозможность профессиональной деятельности в определенных условиях</p>
                  <ul className="list-decimal pl-5 lg:pl-10">
                    <li>
                      <p>Если какие-либо обстоятельства вынуждают Психолога преждевременно прекратить работу с Клиентом и это может отрицательно сказаться на состоянии Клиента, Психолог должен обеспечить продолжение работы с Клиентом.</p>
                    </li>
                    <li>
                      <p>Психолог не должен выполнять свою профессиональную деятельность в случае, когда его способности или суждения находятся под неблагоприятным воздействием.</p>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <p>Принцип ответственности</p>
              <p>Психолог должен помнить  о своих профессиональных и научных обязательствах перед своими клиентами, перед профессиональным сообществом и обществом в целом. Психолог должен стремиться избегать причинения вреда, должен нести ответственность за свои действия, а также гарантировать, насколько это возможно, что его услуги не являются злоупотреблением.</p>
              <p>Принцип ответственности включает:</p>
              <ul className="list-decimal pl-5 lg:pl-10">
                <li>
                  <p>Основная ответственность</p>
                  <ul className="list-decimal pl-5 lg:pl-10">
                    <li>
                      <p>Решение Психолога осуществить исследовательский проект или вмешательство предполагает его ответственность за возможные научные и социальные последствия, включая воздействие на лиц, группы и организации, участвующие в исследовании или вмешательстве, а также непрямой эффект, как, например, влияние научной психологии на общественное мнение и на развитие представлений о социальных ценностях.</p>
                    </li>
                    <li>
                      <p>Психолог должен осознавать специфику взаимодействия с Клиентом и вытекающую из этого ответственность. Ответственность особенно велика в случае, если в качестве испытуемых или клиентов выступают лица, страдающие от медикаментозной зависимости, или лица, ограниченные в своих действиях, а также, если программа исследования или вмешательства целенаправленно ограничивает дееспособность Клиента.</p>
                    </li>
                    <li>
                      <p>Если Психолог приходит к заключению, что его действия не приведут к улучшению состояния Клиента или представляют риск для Клиента, он должен прекратить вмешательство.</p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>Ненанесение вреда</p>
                  <ul className="list-decimal pl-5 lg:pl-10">
                    <li>
                      <p>Психолог применяет только такие методики исследования или вмешательства, которые не являются опасными для здоровья, состояния Клиента, не представляют Клиента в результатах исследования в ложном, искаженном свете, и не дают сведений о тех психологических свойствах и особенностях Клиента, которые не имеют отношения к конкретным и согласованным задачам психологического исследования.</p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>Решение этических дилемм</p>
                  <ul className="list-decimal pl-5 lg:pl-10">
                    <li>
                      <p>Психолог должен осознавать возможность возникновения этических дилемм и нести свою персональную ответственность за их решение. Психологи консультируются по этим вопросам со своими коллегами и другими значимыми лицами, а также информируют их о принципах, отраженных в Этическом кодексе.</p>
                    </li>
                    <li>
                      <p>В случае, если у Психолога в связи с его работой возникли вопросы этического характера, он должен обратиться в Этический комитет Российского психологического общества за консультацией.</p>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <p>Принцип честности</p>
              <p>Психолог должен стремиться содействовать открытости науки, обучения и практики в психологии. В этой деятельности психолог должен быть честным, справедливым и уважающим своих коллег. Психологу надлежит четко представлять свои профессиональные задачи и соответствующие этим задачам функции.</p>
              <p>Принцип честности включает:</p>
              <ul className="list-decimal pl-5 lg:pl-10">
                <li>
                  <p>Осознание границ личных и профессиональных возможностей</p>
                  <ul className="list-decimal pl-5 lg:pl-10">
                    <li>
                      <p>Психолог должен осознавать ограниченность как своих возможностей, так и возможностей своей профессии. Это условие установления диалога между профессионалами различных специальностей.</p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>Честность</p>
                  <ul className="list-decimal pl-5 lg:pl-10">
                    <li>
                      <p>Психолог и Клиент (или сторона, инициирующая и оплачивающая психологические услуги для Клиента) до заключения соглашения оговаривают вопросы вознаграждения и иные существенные условия работы, такие как распределение прав и обязанностей между Психологом и Клиентом (или стороной, оплачивающей психологические услуги) или процедура хранения и применения результатов исследования.
                        Психолог должен известить Клиента или работодателя о том, что его деятельность в первую очередь подчиняется профессиональным, а не коммерческим принципам.</p>
                      <p>При приеме на работу Психолог должен поставить своего работодателя в известность о том, что:</p>
                      <p>– в пределах своей компетенции он будет действовать независимо;</p>
                      <p>– он обязан соблюдать принцип конфиденциальности: этого требует закон;</p>
                      <p>– профессиональное руководство его работой может осуществлять только психолог;</p>
                      <p>– для него невозможно выполнение непрофессиональных требований или требований, нарушающих данный Этический кодекс.
                        При приеме Психолога на работу работодатель должен получить текст данного Этического кодекса.</p>
                    </li>
                    <li>
                      <p>Публичное распространение сведений об оказываемых Психологом услугах служит целям принятия потенциальными Клиентами информированного решения о вступлении в профессиональные отношения с Психологом. Подобная реклама приемлема только в том случае, если она не содержит ложных или искаженных сведений, отражает объективную информацию о предоставляемых услугах и отвечает правилам приличия.</p>
                    </li>
                    <li>
                      <p>Психологу запрещается организовывать рекламу себе или какому-либо определенному методу вмешательства или лечения. Реклама в целях конкуренции ни при каких условиях не должна обманывать потенциальных Клиентов. Психолог не должен преувеличивать эффективность своих услуг, делать заявлений о превосходстве своих профессиональных навыков и применяемых методик, а также давать гарантии результативности оказываемых услуг.</p>
                    </li>
                    <li>
                      <p>Психологу не разрешается предлагать скидку или вознаграждение за направление к ним нему Клиентов или заключать соглашения с третьими лицами с этой целью.</p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>Прямота и открытость</p>
                  <ul className="list-decimal pl-5 lg:pl-10">
                    <li>
                      <p>Психолог должен нести ответственность за предоставляемую им информацию и избегать ее искажения в исследовательской и практической работе.</p>
                    </li>
                    <li>
                      <p>Психолог формулирует результаты исследования в терминах и понятиях, принятых в психологической науке, подтверждая свои выводы предъявлением первичных материалов исследования, их математико-статистической обработкой и положительным заключением компетентных коллег. При решении любых психологических задач проводится исследование, всегда опирающееся на предварительный анализ литературных данных по поставленному вопросу.</p>
                    </li>
                    <li>
                      <p>В случае возникновения искажения информации психолог должен проинформировать об этом участников взаимодействия и заново установить степень доверия.</p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>Избегание конфликта интересов</p>
                  <ul className="list-decimal pl-5 lg:pl-10">
                    <li>
                      <p>Психолог должен осознавать проблемы, которые могут возникнуть в результате двойственных отношений. Психолог должен стараться избегать отношений, которые приводят к конфликтам интересов или эксплуатации отношений с Клиентом в личных интересах.</p>
                    </li>
                    <li>
                      <p>Психолог не должен использовать профессиональные отношения в личных, религиозных, политических или идеологических интересах.</p>
                    </li>
                    <li>
                      <p>Психолог должен осознавать, что конфликт интересов может возникнуть после формального прекращения отношений Психолога с Клиентом. Психолог в этом случае также несет профессиональную ответственность.</p>
                    </li>
                    <li>
                      <p>Психолог не должен вступать в какие бы то ни было личные отношения со своими Клиентами.</p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>Ответственность и открытость перед профессиональным сообществом</p>
                  <ul className="list-decimal pl-5 lg:pl-10">
                    <li>
                      <p>Результаты психологических исследований должны быть доступны для научной общественности. Возможность неверной интерпретации должна быть предупреждена корректным, полным и недвусмысленным изложением. Данные об участниках эксперимента должны быть анонимными. Дискуссии и критика в научных кругах служат развитию науки и им не следует препятствовать.</p>
                    </li>
                    <li>
                      <p>Психолог обязан уважать своих коллег и не должен необъективно критиковать их профессиональные действия.</p>
                    </li>
                    <li>
                      <p>Психолог не должен своими действиями способствовать вытеснению коллеги из его сферы деятельности или лишению его работы.</p>
                    </li>
                    <li>
                      <p>Если Психолог считает, что его коллега действует непрофессионально, он должен указать ему на это конфиденциально.</p>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>

          <h2 className="text-blackBase lg:text-lg font-bold">II. Нарушение Этического кодекса психолога</h2>
          <ul className="text-[13px] lg:text-[14px] text-greyBase list-decimal pl-5 lg:pl-10">
            <li>
              <p>Нарушение Этического кодекса психолога включает в себя игнорирование изложенных в нем положений, неверное их толкование или намеренное нарушение. Нарушение Этического кодекса может стать предметом жалобы.</p>
            </li>
            <li>
              <p>Жалоба на нарушение Этического кодекса психолога может быть подана в Этический комитет Российского психологического общества в письменном виде любым физическим и юридическим лицом. Рассмотрение жалоб и вынесение решений по ним осуществляется в установленном порядке Этическим комитетом Российского психологического общества.</p>
            </li>
            <li>
              <p>В качестве санкций, применяемых к Психологу, нарушившему Этический кодекс, могут выступать: предупреждение от имени Российского психологического общества (общественное порицание), приостановление членства в Российском психологическом обществе, сопровождающееся широким информированием общественности и потенциальных клиентов об исключении данного специалиста из действующего реестра психологов РПО. Информация о применяемых санкциях является общедоступной и передается в профессиональные психологические ассоциации других стран.</p>
            </li>
            <li>
              <p>В случае серьезных нарушений Этического кодекса Российское психологическое общество может ходатайствовать о привлечении Психолога к суду.</p>
            </li>
          </ul>

          <p className="ext-[13px] lg:text-[14px] text-greyBase">Настоящий Этический кодекс психолога принят “14” февраля 2012 года V съездом Российского психологического общества.</p>
        </div>
      </div>
      <div className="flex justify-between md:items-center flex-col md:flex-row gap-5 pt-6">
        <div className="flex flex-col gap-2.5">
          <Checkbox label="Я подтверждаю, что буду придерживаться этического кодекса психолога"
                    checked={agreeWithCodex}
                    onChange={onAgreeCodex}
                    error={errorAgreeWithCodex}
          />
        </div>
        <button
          className={classNames('w-full md:w-auto h-auto font-comfortaa font-bold flex justify-center p-[12px_40px] bg-redBase text-white text-sm md:text-base rounded-[10px] disabled:pointer-events-none disabled:opacity-50 hover:bg-redBaseWithBlur20', {

          })}
          disabled={!isActiveBtn && !userData?.agree_with_code}
          onClick={onConfirmCodex}
        >
          Подтвердить
        </button>
      </div>
    </Popup>
  )
}

export default ConfirmCodex