import { FC, useEffect, useState } from 'react';
import { QuestionsCircleIcon } from '../../icons/question-circle';
import { CloseCrossBtn } from '../../icons/close-cross-btn';
import Button from '../../common/button';
import { SettingsButton } from '../../icons/settings-button';
import { getTimeTzNoLess } from '../../../utils/getTime';
import { PlusIcon } from '../../icons/plus';
import { CalendarIcon } from '../../icons/calendar';
import { IConsultation } from '../../../store/types';
import { ConsultationCard } from '../consultation-card';
import dayjs from 'dayjs';

type ConsultationTableProps = {
  setOpenSettings: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenCalendar: React.Dispatch<React.SetStateAction<boolean>>;
  openForm: () => void;
  consultationList: IConsultation[];
  clientsList: { id: number; name: string }[];
  handleChangeCons: (a: number) => void;
  openEditFormClient: (id: number) => void;
};

export const ConsultationTable: FC<ConsultationTableProps> = ({
  setOpenSettings,
  setOpenCalendar,
  handleChangeCons,
  openForm,
  openEditFormClient,
  consultationList,
  clientsList,
}) => {
  const [modalConclusion, setModalConclusion] = useState(false);
  const [time, setTime] = useState(getTimeTzNoLess(new Date()));

  useEffect(() => {
    //Implementing the setInterval method
    const interval = setInterval(() => {
      setTime(getTimeTzNoLess(new Date()));
    }, 1000);

    //Clearing the interval
    return () => clearInterval(interval);
  }, [time]);

  return (
    <div className="flex flex-col gap-10 sm:gap-3 w-full h-full">
      <div className="w-full flex flex-col gap-[30px] sm:flex-row justify-between relative">
        <div className="flex gap-4 items-center ">
          <h1 className="font-comfortaa font-semibold text-lg sm:text-2xl text-blackBase">
            Консультации
          </h1>
          <span onClick={() => setModalConclusion(true)}>
            <QuestionsCircleIcon width={21} height={21} className="cursor-pointer" />
          </span>
          {modalConclusion && (
            <div className="flex flex-col absolute -top-[75px] sm:-top-[70px] left-[165px] sm:left-[215px] z-[1000] bg-white rounded-[5px] border border-[#dd7373] max-w-[185px] p-[8px]">
              <button
                className="ml-auto absolute top-[5px] right-[5px]"
                onClick={() => setModalConclusion(false)}>
                <CloseCrossBtn width={12} height={12} />
              </button>
              <p className="text-[10px] text-[#585858]">
                На странице отображаются встречи на СЕГОДНЯ, остальные – в Календаре встреч.
              </p>
            </div>
          )}
        </div>
        <Button
          type={'black'}
          onClick={() => setOpenSettings(true)}
          className=" sm:w-max !py-0 h-[40px] !text-sm sm:!text-base !font-medium !leading-4 group flex items-center gap-5">
          <SettingsButton className="fill-white group-hover:fill-redBase" />
          Настройки
        </Button>
      </div>
      <div className="p-[20px] flex flex-col sm:flex-row justify-between w-full h-full gap-5 bg-[#F6F6F6] rounded-[30px_30px_0px_0px]">
        <div className="w-full sm:max-w-[180px] flex sm:flex-col gap-[30px] flex-wrap sm:flex-nowrap">
          <div className="w-full max-w-[180px] bg-white flex flex-col py-5 px-[30px] rounded-[20px] h-[126px]">
            <p className="text-[#a09fa7] text-sm mb-[5px]">Сегодня</p>
            <p className="text-redBase text-2xl font-semibold mb-2.5">{time}</p>
            <p className="text-[#111026] text-base font-medium">
              {dayjs().format('DD.MM.YYYY, dd')}
            </p>
          </div>
          <Button
            className="!text-sm max-w-[180px] font-bold group flex flex-col items-center gap-[5px] rounded-[20px]"
            onClick={() => openForm()}>
            <PlusIcon className="stroke-white" />
            Создать встречу
          </Button>
          <Button
            className="!text-sm max-w-[180px] font-bold group flex flex-col items-center gap-[5px] rounded-[20px]"
            onClick={() => setOpenCalendar((prev) => !prev)}>
            <CalendarIcon className="stroke-white" />
            Календарь встреч
          </Button>
        </div>
        <div className="w-full h-full max-h-[600px] max-w-[720px] lg:max-w-[600px] flex flex-col gap-10 overflow-y-auto no-scrollbar pb-5">
          {consultationList?.map((item, idx) => {
            const client = clientsList.find(
              (el) => `/api/v1/clients/${el.id}` === item.client_entity
            );
            return (
              <ConsultationCard
                openEditFormClient={openEditFormClient}
                item={item}
                handleChangeCons={handleChangeCons}
                client={client}
                key={idx}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
