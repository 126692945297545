import { ConfigProvider, DatePicker } from 'antd';
import { FC, useEffect, useRef, useState } from 'react';
import 'dayjs/locale/ru';
import locale from 'antd/lib/date-picker/locale/ru_RU';
import classNames from 'classnames';
import dayjs from 'dayjs';

export const DatePickerCustom: FC<{
  date?: string;
  setDate: (a: any) => void;
  className?: string;
  label?: string;
  required?: boolean;
}> = ({ className, setDate, label, date, required }) => {
  const [focusedDate, setfocusedDate] = useState(false);
  const dateRef = useRef<any>();

  return (
    <div className="w-full relative flex flex-col gap-[5px]">
      {label && (
        <div
          className="w-full flex items-center justify-between"
          onClick={() => dateRef.current.focus()}>
          <p className="text-xs text-[#585858]">
            {label} {required ? ' *' : ''}
          </p>
        </div>
      )}
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#C00000',
            colorLink: '#111026',
          },
          components: {
            DatePicker: {
              activeShadow: 'none',
              hoverBorderColor: '#111026',
              activeBorderColor: '#111026',
            },
          },
        }}>
        <DatePicker
          className={classNames(
            'w-full h-[48px] p-[10px_15px] md:p-[13px_20px] w-full text-blackBase [&>div>input]:!text-[16px] rounded-[10px] border border-[1px] border-blackBase placeholder:text-[#585858] z-[2] bg-transparent !font-manrope',

            className,
            {
              ['!border-[2px]']: focusedDate,
            }
          )}
          defaultValue={date ? dayjs(date) : undefined}
          value={date ? dayjs(date) : undefined}
          ref={dateRef}
          placeholder="01.01.2000"
          onChange={(_, val) => {
            setfocusedDate(false);
            setDate(
              typeof val === 'string'
                ? val.split('.').reverse().join('-')
                : val[0].split('.').reverse().join('-')
            );
          }}
          locale={locale}
          format={'DD.MM.YYYY'}
          open={focusedDate}
          onFocus={() => setfocusedDate(true)}
          onOpenChange={(open) => setfocusedDate(open)}
        />
      </ConfigProvider>
    </div>
  );
};
