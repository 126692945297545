import React, { FC, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { LogoIcon } from '../icons/logo';
import { LANDING } from '../../configs/api/api.config';
import Button from '../common/button';
import { Settings } from '../icons/settings';
import { socket } from '../../libs/socket';
import { getDate, getTime } from '../../utils/getTime';
import { SettingsModal } from '../settings/settings-modal';
import { IConsultation } from '../../store/types';
import { toast } from 'react-toastify';

type Props = {
  consultation: IConsultation | null;
  videoRoom: any;
};

export const ClientJoin: FC<Props> = ({ consultation, videoRoom }) => {
  const params = useParams();
  const roomName = params.id;
  const echoTestRef = useRef<any>(null);

  const handleJoin = () => {
    const checkRoom = localStorage.getItem('roomName');
    if (checkRoom && checkRoom === roomName) {
      toast.error('Вы уже вошли в комнату');
      return;
    } else {
      localStorage.setItem('roomName', String(roomName));
    }
    socket.emit('join', roomName);
    videoRoom.joinRoom(roomName);
  };

  const [openSettings, setOpenSettings] = useState(false);

  const start = () => {
    if (echoTestRef.current) {
      echoTestRef.current.start();
    }
  };

  useEffect(() => {
    if (openSettings) {
      const interval = setInterval(() => {
        start();
      }, 1000);

      //Clearing the interval
      return () => clearInterval(interval);
    }
  }, [openSettings]);

  return (
    <>
      <div className="h-full flex flex-col items-center pt-[50px] lg:pt-[80px] fixed w-screen h-screen z-[1002] !bg-white">
        <div className="fixed top-0 left-0 z-50 w-screen h-headerHeightMobile lg:h-headerHeight bg-white shadow-shadowHeader">
          <div className="w-full max-w-[1280px] h-full py-[8px] px-[15px] lg:py-2 mx-auto">
            <div className="h-full flex flex-col">
              <div className="text-xs md:text-base text-black font-bold leading-3 md:leading-4 mb-[3px] md:mb-[7px]">
                BETA
              </div>
              <Link to={LANDING}>
                <LogoIcon className="w-[80px] h-[15px] lg:w-[155px] lg:h-[30px]" />
              </Link>
            </div>
          </div>
        </div>
        {consultation && (
          <div className="w-full h-full lg:w-[700px] flex flex-col items-center py-[4vh]">
            <h2 className="flex flex-col font-comfortaa font-bold text-blackBlue text-xl lg:text-4xl mb-[4vh] text-center">
              <span>ZiNGiZi</span>
              <span>Консультация</span>
            </h2>
            <div className="flex gap-[22px] lg:gap-[45px] mb-[4vh]">
              <div className="w-[140px]">
                <div className="w-[140px] h-[140px] overflow-hidden">
                  <img
                    className="w-full h-full object-contain"
                    src={`${process.env.REACT_APP_API_URL}/${consultation.psychologist.avatar}`}
                    alt="Психолог"
                  />
                </div>
              </div>
              <div className="flex flex-col gap-[8px]">
                <div className="text-blackBase text-center p-[5px_20px] rounded-[10px] bg-greenLight mb-[7px]">
                  Психолог ожидает
                </div>
                <div className="flex gap-[15px]">
                  <span className="text-greyLightBlue">Дата:</span>
                  <span className="text-blackBase">{getDate(new Date(consultation?.date!))}</span>
                </div>
                <div className="flex gap-[15px]">
                  <span className="text-greyLightBlue">Время:</span>
                  <span className="text-blackBase">{`${
                    consultation?.datetime_start && getTime(new Date(consultation!.datetime_start))
                  } - ${
                    consultation?.datetime_end && getTime(new Date(consultation?.datetime_end))
                  }`}</span>
                </div>
                <div className="flex gap-[15px]">
                  <span className="text-greyLightBlue">Психолог:</span>
                  <span className="text-blackBase">{`${consultation.psychologist.first_name}`}</span>
                </div>
                <div className="flex gap-[15px]">
                  <span className="text-greyLightBlue">Сессия:</span>
                  <span className="text-blackBase">{`№${consultation.consultation_number}`}</span>
                </div>
              </div>
            </div>

            <Button
              className="!w-[344px] sm:!w-[390px] mb-[10px] p-[15px_20px] md:p-[15px_60px] max-w-[90%]"
              onClick={() => handleJoin()}>
              Подключиться к сессии
            </Button>
            <small className="text-[#70707c] text-[9px] text-center max-w-[344px] sm:max-w-[390px] mb-[4vh]">
              Нажимая кнопку «Подключиться к сессии», я принимаю условия{' '}
              <Link
                className="underline"
                to={`${LANDING}/Пользовательское_соглашение_ЛОГОСТЕРАПИ_REV.pdf`}
                target="_blank">
                Пользовательского соглашения
              </Link>
              , даю{' '}
              <Link
                className="underline"
                to={`${LANDING}/Политика_Конфиденциальности_ЛОГОСТЕРАПИ_REV.pdf`}
                target="_blank">
                согласие на обработку персональных данных
              </Link>{' '}
              и подтверждаю предоставление Психологу своего согласия на обработку моих персональных
              данных.
            </small>
            <p className="text-center text-greyBase text-sm lg:text-base mb-[4vh] lg:mb-[30px]  max-w-[344px]">
              Проверьте настройки видео-конференции перед её началом.
            </p>
            <Button
              className="!w-[344px] sm:!w-[390px] p-[15px_30px] flex items-center relative max-w-[90%]"
              type="grey"
              onClick={() => setOpenSettings(true)}>
              <span className="grow">Настройки</span>

              <Settings
                needHover={false}
                fill="none"
                className="absolute top-[50%] right-[20px] -translate-y-1/2 z-1"
              />
            </Button>
          </div>
        )}
      </div>
      {openSettings && (
        <SettingsModal
          echoTestRef={echoTestRef}
          openSettings={openSettings}
          setOpenSettings={setOpenSettings}
        />
      )}
    </>
  );
};
