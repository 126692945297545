import { Dispatch, FC } from 'react';
import { CloseCall } from '../../icons/close-call';
import { DpdgIcon } from '../../icons/dpdg';
import { FaceSmile } from '../../icons/face-smile';
import { MicroIcon } from '../../icons/micro';
import { PictureIcon } from '../../icons/picture';
import { QuestionIcon } from '../../icons/question';
import { Settings } from '../../icons/settings';
import { VideoIcon } from '../../icons/video';
import { DropDownIcons } from '../dropdown-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../hooks/redux';
import { selectUser } from '../../../store/auth.slice';
import { socket } from '../../../libs/socket';
import { MacIcon } from '../../icons/mac';

interface IProps {
  menu?: number;
  setMenu?: (num: number) => void;
  videoRoom?: any;
  audioMute: boolean;
  setAudioMute: React.Dispatch<React.SetStateAction<boolean>>;
  videoMute: boolean;
  setVideoMute: React.Dispatch<React.SetStateAction<boolean>>;
  isPanelOpen: boolean;
  videoJsPlayer: any;
  setVideoJsPlayer: Dispatch<any>;
  setOpenSettings: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SettingPanel: FC<IProps> = ({
  menu,
  setMenu,
  videoRoom,
  audioMute,
  videoMute,
  setVideoMute,
  setAudioMute,
  isPanelOpen,
  videoJsPlayer,
  setVideoJsPlayer,
  setOpenSettings,
}) => {
  const userData = useAppSelector(selectUser);
  const { id } = useParams();
  const navigate = useNavigate();

  const onSetMenu = (num: number) => {
    if (setMenu) {
      setMenu(num === menu ? 0 : num);
    }
  };

  const closeMeet = () => {
    localStorage.removeItem('roomName');
    videoJsPlayer && videoJsPlayer.pause();
    if (!audioMute) {
      setAudioMute(!videoRoom.toggleAudio());
    }
    if (!videoMute) {
      setVideoMute(!videoRoom.toggleVideo());
    }
    videoRoom.leaveRoom();
    videoJsPlayer && videoRoom.detachVideoPlayer(videoJsPlayer);
    setVideoJsPlayer(null);
    if (!userData) {
      socket.emit('message', {
        roomId: id,
        message: {
          datetime: new Date().toISOString(),
          action: 'close_tools',
        },
      });
    }
    navigate(`/end-call/${id}`);
  };
  return (
    <div className="w-full flex gap-[15px] bottom-0 z-[1001] bg-white fixed lg:gap-[30px] justify-between items-center px-4 py-2.5">
      {userData ? (
        <>
          <div className="hidden md:flex gap-[15px] lg:gap-[30px] items-center">
            {userData?.active_psy_tools?.includes('emotions_detection') && (
              <button onClick={() => onSetMenu(1)} className="w-auto p-0">
                <FaceSmile
                  className="w-[30px] h-[30px] md:w-[48px] md:h-[48px]"
                  fillBg={menu === 1 ? '#DEC05E' : '#828282'}
                  fill={menu === 1 ? '#828282' : '#fff'}
                />
              </button>
            )}
            {userData?.active_psy_tools?.includes('metaforic_cards') && (
              <button onClick={() => onSetMenu(3)} className="w-auto p-0">
                <MacIcon
                  className="w-[30px] h-[30px] md:w-[48px] md:h-[48px]"
                  fillBg={menu === 3 || isPanelOpen ? '#DEC05E' : '#828282'}
                  fill={menu === 3 || isPanelOpen ? '#828282' : '#fff'}
                />
              </button>
            )}
            {userData?.active_psy_tools?.includes('new_question') && (
              <button onClick={() => onSetMenu(4)} className="w-auto p-0">
                <QuestionIcon
                  className="w-[30px] h-[30px] md:w-[48px] md:h-[48px]"
                  fillBg={menu === 4 ? '#DEC05E' : '#828282'}
                  fill={menu === 4 ? '#828282' : '#fff'}
                />
              </button>
            )}
            {userData?.active_psy_tools?.includes('dpdg') && (
              <button onClick={() => onSetMenu(2)} className="w-auto p-0">
                <DpdgIcon
                  className="w-[30px] h-[30px] md:w-[48px] md:h-[48px]"
                  fillBg={menu === 2 ? '#DEC05E' : '#828282'}
                  fill={menu === 2 ? '#828282' : '#fff'}
                />
              </button>
            )}
          </div>
          <div className="flex gap-[15px] lg:gap-[30px] items-center">
            <button
              onClick={() => {
                const a = !videoRoom.toggleAudio();
                setAudioMute(a);
                socket.emit('message', {
                  roomId: id,
                  message: {
                    datetime: new Date().toISOString(),
                    action: 'micro_client',
                    data: a,
                  },
                });
              }}>
              <MicroIcon
                className="w-[30px] h-[30px] md:w-[48px] md:h-[48px]"
                checked={audioMute}
              />
            </button>
            <button
              onClick={() => {
                const a = !videoRoom.toggleVideo();
                setVideoMute(a);
                socket.emit('message', {
                  roomId: id,
                  message: {
                    datetime: new Date().toISOString(),
                    action: 'video_client',
                    data: a,
                  },
                });
              }}>
              <VideoIcon
                className="w-[30px] h-[30px] md:w-[48px] md:h-[48px]"
                checked={videoMute}
              />
            </button>
          </div>
          <div className="flex gap-[15px] lg:gap-[30px] items-center">
            <button onClick={() => setOpenSettings(true)}>
              <Settings className="w-[30px] h-[30px] md:w-[48px] md:h-[48px]" />
            </button>
            <button onClick={() => closeMeet()}>
              <CloseCall className="w-[30px] h-[30px] md:w-[48px] md:h-[48px]" />
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="flex gap-[15px] lg:gap-[30px] items-center mx-auto">
            <button
              onClick={() => {
                const a = !videoRoom.toggleAudio();
                setAudioMute(a);
                socket.emit('message', {
                  roomId: id,
                  message: {
                    datetime: new Date().toISOString(),
                    action: 'micro_client',
                    data: a,
                  },
                });
              }}>
              <MicroIcon
                className="w-[30px] h-[30px] md:w-[48px] md:h-[48px]"
                checked={audioMute}
              />
            </button>
            <button
              onClick={() => {
                const a = !videoRoom.toggleVideo();
                setVideoMute(a);
                socket.emit('message', {
                  roomId: id,
                  message: {
                    datetime: new Date().toISOString(),
                    action: 'video_client',
                    data: a,
                  },
                });
              }}>
              <VideoIcon
                className="w-[30px] h-[30px] md:w-[48px] md:h-[48px]"
                checked={videoMute}
              />
            </button>
            <button onClick={() => closeMeet()}>
              <CloseCall className="w-[30px] h-[30px] md:w-[48px] md:h-[48px]" />
            </button>
          </div>
          <button onClick={() => setOpenSettings(true)}>
            <Settings className="w-[30px] h-[30px] md:w-[48px] md:h-[48px]" />
          </button>
        </>
      )}
    </div>
  );
};
