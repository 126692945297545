import classNames from 'classnames';
import { FC, useState } from 'react';
import Button from '../common/button';
import { socket } from '../../libs/socket';
import { useParams } from 'react-router-dom';
import { CloseCircleCrossBtn } from '../icons/close-circle-cross-btn';

export const MacCardList: FC<{
  cards: { id: number; img: string; selected: boolean; show: boolean; deck: string }[];
  selectedCards: {
    id: number;
    img: string;
    selected: boolean;
  }[];
  setSelectedCards: (
    a: {
      id: number;
      img: string;
      selected: boolean;
    }[]
  ) => void;
}> = ({ cards, setSelectedCards, selectedCards }) => {
  const [activeItems, setActiveItems] = useState<number[]>([]);
  const [isMacModule, setIsMacModule] = useState(true);
  const params = useParams();
  const roomName = params.id;

  const handleChooseCard = (id: number) => {
    if (selectedCards.filter((el) => el.selected).length > 0) return;
    if (activeItems.length === 4 && !activeItems.includes(id)) return;
    if (activeItems.includes(id)) {
      setActiveItems((prev) => prev.filter((el) => el !== id));
      return;
    }
    setActiveItems([...activeItems, id]);
  };

  const getChooseToPsy = () => {
    const arr = cards.map((el) => {
      if (activeItems.includes(el.id)) {
        return { ...el, selected: !el.selected };
      } else {
        return el;
      }
    });

    socket.emit('message', {
      roomId: roomName,
      message: {
        datetime: new Date().toISOString(),
        action: 'select_cards',
        data: {
          name: 'metaforic_cards',
          result: arr.filter((el) => el.selected),
        },
      },
    });
    setSelectedCards(arr);
    setIsMacModule(false);
  };
  return (
    <>
      {isMacModule && (
        <div className="w-full h-full flex gap-2.5 lg:gap-5 justify-between">
          <div
            className={
              'w-full flex flex-col bg-grey1 rounded-l-[5px] lg:rounded-[15px] p-[10px_15px] md:p-4 relative shrink sm:rounded-l-[15px]'
            }>
            <h2 className="text-white text-sm lg:text-2xl mb-[15px] lg:mb-[20px] font-comfortaa font-bold">
              МАК-карты
            </h2>

            <p className="text-white text-xs lg:text-sm text-center mb-[15px] lg:mb-[20px]">
              Выберите максимум 4 карты из колоды
            </p>

            <div className="h-full flex overflow-y-auto mb-[20px] pr-2.5 white-scroll">
              <div className="w-full grid grid-cols-2 lg:grid-cols-3 gap-[8px] sm:gap-3 max-h-[195px] sm:max-h-none">
                {cards.map((item) => {
                  if (item.show || activeItems.includes(item.id)) {
                    return (
                      <div
                        className={classNames(
                          'min-h-[280px] rounded-[10px] lg:rounded-[20px] overflow-hidden border border-[3px] border-transparent',
                          {
                            ' !border-goldBase': activeItems.includes(item.id),
                          }
                        )}
                        onClick={() => handleChooseCard(item.id)}
                        key={item.id}>
                        <img
                          src={`${process.env.REACT_APP_API_URL}/${item.img}`}
                          className="w-full h-full object-cover"
                          alt={String(item.id)}
                        />
                      </div>
                    );
                  }
                  return (
                    <div
                      className={classNames(
                        'min-h-[280px] rounded-[10px] lg:rounded-[20px] overflow-hidden text-white flex flex-col items-center justify-center text-center',
                        {
                          'border border-[3px] border-goldBase': activeItems.includes(item.id),
                          ['border border-[3px] !border-goldBase']:
                            activeItems.includes(item.id) && item.deck !== 'Деньги',
                          ['bg-[#C00000] border-[#C00000]']: item.deck === 'Любовь и отношения',
                          ['bg-[#DEC05E] border-[#DEC05E]']: item.deck === 'Деньги',
                          ['border border-[3px] !border-[#C00000]']:
                            activeItems.includes(item.id) && item.deck === 'Деньги',
                          [' bg-white !text-[#C00000] border-white']: item.deck === 'Ресурс',
                        }
                      )}
                      onClick={() => handleChooseCard(item.id)}
                      key={item.id}>
                      <p className="font-manrope uppercase text-xl font-extrabold text-center">
                        {item.deck}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="w-full max-w-[222px] flex justify-center mx-auto">
              <Button
                className="md:w-auto !text-base p-[8px_20px] font-medium !w-full"
                onClick={() => getChooseToPsy()}
                disabled={activeItems.length === 0}>
                Выбрать
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
