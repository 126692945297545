import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ArrowLeft } from '../../components/icons/arrow-left';
import Button from '../../components/common/button';
import macCardMock8 from '../../assets/images/mac-cards/mac-card-8-full.png';
import { Love } from '../../components/icons/reactions/love';
import { Joy } from '../../components/icons/reactions/joy';
import { Anger } from '../../components/icons/reactions/anger';
import { Fear } from '../../components/icons/reactions/fear';
import { Sadness } from '../../components/icons/reactions/sadness';
import { QuestionsCircleIcon } from '../../components/icons/question-circle';
import ModalWrap from '../../components/common/modal-wrap';
import { CloseCrossBtn } from '../../components/icons/close-cross-btn';
import { Switch } from '../../components/common/switch';
import { CreateClientForm, IFormDataClient } from '../../components/create-client-form';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../hooks/redux';
import { selectUser } from '../../store/auth.slice';
import {
  useChangeClientMutation,
  useLazyGetClientQuery,
  useLazyGetConsultationQuery,
  useLazyGetConsultationsQuery,
} from '../../services/users.api';
import dayjs from 'dayjs';
import { getTime } from '../../utils/getTime';
import axios from 'axios';
type Comments = {
  emotions: string[];
  mac_cards: string[];
  dpdg: string;
  result: string;
};

const JournalCard = () => {
  const userData = useAppSelector(selectUser);
  const [editMode, setEditMode] = useState(false);
  const [modalConclusion, setModalConclusion] = useState(false);
  const [modalWithClient, setModalWithClient] = useState(false);
  const [isEditClient, setiIsEditClient] = useState(false);
  const params = useParams();
  const [getClient, { data: clientData }] = useLazyGetClientQuery();
  const [getConsultation, { data: consultation }] = useLazyGetConsultationQuery();
  const [getConsultations, { data: consultations }] = useLazyGetConsultationsQuery();
  const [changeClient] = useChangeClientMutation();
  const [commentsData, setCommentsData] = useState<Comments>({
    emotions: [],
    mac_cards: [],
    dpdg: '',
    result: '',
  });

  useEffect(() => {
    if (params.id) {
      getConsultation(`${params.id}`);
    }
  }, []);

  useEffect(() => {
    const userId = consultation?.client_entity.split('/').at(-1);
    if (userId) {
      getClient(userId);
    }
    if (consultation) {
      getConsultations({ client_entity: consultation?.client_entity });
    }
  }, [consultation]);

  const getEmotionSmile = (val: string) => {
    switch (val) {
      case 'Любовь':
        return (
          <Love
            needHover={false}
            className="w-[48px] h-[48px] md:w-[80px] md:h-[80px] !cursor-default"
          />
        );
      case 'Радость':
        return (
          <Joy
            needHover={false}
            className="w-[48px] h-[48px] md:w-[80px] md:h-[80px] !cursor-default"
          />
        );
      case 'Гнев':
        return (
          <Anger
            needHover={false}
            className="w-[48px] h-[48px] md:w-[80px] md:h-[80px] !cursor-default"
          />
        );
      case 'Страх':
        return (
          <Fear
            needHover={false}
            className="w-[48px] h-[48px] md:w-[80px] md:h-[80px] !cursor-default"
          />
        );
      case 'Грусть':
        return (
          <Sadness
            needHover={false}
            className="w-[48px] h-[48px] md:w-[80px] md:h-[80px] !cursor-default"
          />
        );
      default:
        return null;
    }
  };

  const handleSaveClient = async (formDataClient: IFormDataClient) => {
    if (!clientData?.id) {
      return;
    }
    try {
      const data = {
        id: clientData.id,
        email: formDataClient.email,
        full_name: formDataClient.name,
        phone: formDataClient.phone,
        birthday: formDataClient.date ? formDataClient.date : null,
        time_zone: formDataClient.tz,
        telegram: formDataClient.tg,
        vk: formDataClient.vk,
        note: formDataClient.info,
        is_active: formDataClient.active,
      };
      await changeClient(data);
      toast.success('Клиент успешно изменен');
    } catch (error) {
      console.log(error);
    }
  };

  const meetData = consultation?.result && consultation?.result[0];

  const handleSave = async () => {
    try {
      const emotionsData =
        meetData?.emotions.map((el, idx) => {
          return { ...el, comment: commentsData.emotions.find((_, index) => idx === index) || '' };
        }) || [];

      const macCardsData =
        meetData?.mac_cards.map((el, idx) => {
          return { ...el, comment: commentsData.mac_cards.find((_, index) => idx === index) || '' };
        }) || [];
      const data = {
        ...meetData,
        emotions: emotionsData,
        mac_cards: macCardsData,
        dpdg: meetData?.dpdg
          ? {
              ...meetData?.dpdg,
              comment: commentsData.dpdg,
            }
          : undefined,
        result: commentsData.result,
      };
      const getToken = () => localStorage.getItem('tokensZZ');
      if (!consultation || !userData) return;
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/v1/consultations/${consultation?.id}`,
        { result: [{ ...data }] },
        {
          headers: {
            // @ts-ignore
            Authorization: `Bearer ${JSON.parse(getToken()).token}`,
          },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        setEditMode(false);
        getConsultation(`${params.id}`);
        toast.success('Данные успешно сохранены');
      }
    } catch (error) {
      console.log(error);
      toast.error('Произошла ошибка');
    }
  };

  useEffect(() => {
    if (meetData) {
      const data = {
        emotions: meetData?.emotions?.map((el) => el.comment) || [],
        mac_cards: meetData?.mac_cards?.map((el) => el.comment) || [],
        dpdg: meetData?.dpdg ? meetData.dpdg.comment : '',
        result: meetData.result,
      };
      setCommentsData(data);
    }
  }, [meetData]);

  console.log(commentsData.emotions);
  return (
    <div className="pb-[50px]">
      <div className="flex flex-col md:flex-row justify-between md:items-center mb-10">
        <h2 className="flex items-center gap-[14px] font-comfortaa font-semibold text-xl text-blackBase mb-[26px] md:mb-0">
          <Link to={'/journal'} className="group">
            <ArrowLeft className="fill-blackBase group-hover:fill-redBase" width={21} height={16} />
          </Link>
          <span>
            {`${dayjs(consultation?.date).format('DD MMMM YYYY')}, ${
              consultation &&
              `${getTime(new Date(consultation?.datetime_start))} - ${getTime(
                new Date(consultation?.datetime_end)
              )}`
            }`}
          </span>
        </h2>
        <div className="w-full md:w-max">
          <Button
            type="black"
            className="!p-[6px_30px] text-sm md:!text-base"
            onClick={() => setEditMode((prev) => !prev)}>
            {editMode ? 'Отмена' : 'Редактировать'}
          </Button>
        </div>
      </div>
      <div className="flex gap-[15px] mb-[30px]">
        <div className="text-greyBase lg:text-lg">Клиент:</div>
        <div
          className="font-medium text-blackBase lg:text-lg cursor-pointer"
          onClick={() => setModalWithClient(true)}>
          {clientData?.full_name}
        </div>
      </div>
      {meetData?.mac_cards && meetData?.mac_cards.length > 0 && (
        <div className="mb-[20px]">
          <h2 className="font-comfortaa font-semibold text-lg lg:text-xl text-blackBase mb-[10px]">
            Мак-карта
          </h2>
          <div className="flex flex-col gap-10">
            {meetData.mac_cards.map((el, idx) => (
              <div className="flex flex-col gap-10" key={idx}>
                <div className="flex gap-[16px] flex-wrap">
                  {el.cards.map((card, idx) => (
                    <div
                      key={idx}
                      className="w-[120px] h-[160px] shrink-0 rounded-[10px] overflow-hidden">
                      <img
                        src={`${process.env.REACT_APP_API_URL}/${card}`}
                        className="w-full h-full object-cover"
                        alt=""
                      />
                    </div>
                  ))}
                </div>
                <div>
                  <div className="flex gap-[15px] text-sm mb-[10px]">
                    <div className="text-greyBase">Время использования:</div>
                    <div className="font-medium text-blackBase">
                      {dayjs(el?.date_time).format('HH:mm')}
                    </div>
                  </div>
                  <div className="font-medium text-blackBase mb-[5px]">Комментарии психолога</div>
                  {!editMode && <div className="text-greyBase">{el.comment}</div>}
                  {editMode && (
                    <div>
                      <div>
                        <textarea
                          value={commentsData.mac_cards[idx]}
                          onChange={(e) => {
                            const a = commentsData.mac_cards.map((el, index) => {
                              if (idx === index) {
                                return e.target.value;
                              } else {
                                return el;
                              }
                            });
                            setCommentsData((prev) => {
                              return { ...prev, mac_cards: a };
                            });
                          }}
                          className="w-full min-h-[120px] border border-greyBase rounded-[10px] p-[13px_20px]"
                          placeholder="Введите текст, например, ассоциации клиента, эврики, телесные “сигналы” и т.п."
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {meetData?.emotions && meetData?.emotions.length > 0 && (
        <div className="mb-[20px]">
          <h2 className="font-comfortaa font-semibold text-lg lg:text-xl text-blackBase mb-[10px]">
            Эмоциональное состояние
          </h2>
          {meetData.emotions.map((el, idx) => (
            <div className="flex flex-col  mb-[10px]" key={idx}>
              <div className="grid grid-cols-1 xs:grid-cols-2 gap-4">
                {el.emotions.map((emotion, idx) => (
                  <div className="flex flex-col md:flex-row gap-2" key={idx}>
                    <div className="md:w-[120px] md:h-[160px] shrink-0 rounded-[10px] overflow-hidden">
                      <div className="w-max md:w-[120px] flex md:flex-col items-center justify-center self-center gap-[15px] bg-grey5 p-[10px] md:p-5 rounded-[10px]">
                        <div>{getEmotionSmile(emotion.category)}</div>
                        <p className="font-medium text-blackBase">{emotion.category}</p>
                      </div>
                    </div>
                    <div>
                      <div className="w-full flex flex-wrap flex-col md:flex-row md:items-center gap-[5px] text-blackBase font-medium mb-[10px]">
                        {emotion.items.map((item, idx) => (
                          <div className="bg-goldBase w-max p-[5px_15px] rounded-[5px]" key={idx}>
                            {item}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="flex gap-[15px] text-sm my-[10px]">
                <div className="text-greyBase">Время использования:</div>
                <div className="font-medium text-blackBase">
                  {dayjs(el.date_time).format('HH:mm')}
                </div>
              </div>
              <div className="font-medium text-blackBase mb-[5px]">Комментарии психолога</div>
              {editMode && (
                <div>
                  <div>
                    <textarea
                      value={commentsData.emotions[idx]}
                      onChange={(e) => {
                        const a = commentsData.emotions.map((el, index) => {
                          if (idx === index) {
                            return e.target.value;
                          } else {
                            return el;
                          }
                        });
                        setCommentsData((prev) => {
                          return { ...prev, emotions: a };
                        });
                      }}
                      className="w-full min-h-[120px] border border-greyBase rounded-[10px] p-[13px_20px]"
                      placeholder="Введите текст, например, как клиент прокомментировал свое состояние"
                    />
                  </div>
                </div>
              )}
              {!editMode && <div className="text-greyBase">{el.comment}</div>}
            </div>
          ))}
        </div>
      )}

      <div className="mb-[20px]">
        {meetData?.dpdg && (
          <>
            <h2 className="font-comfortaa font-semibold text-lg lg:text-xl text-blackBase mb-[10px]">
              Десенсибилизация и переработка движением глаз (ДПДГ)
            </h2>

            {meetData.dpdg?.date_time && (
              <div className="flex gap-[15px] text-sm mb-[10px]">
                <div className="text-greyBase">Время использования:</div>
                <div className="font-medium text-blackBase">
                  {dayjs(meetData.dpdg?.date_time).format('HH:mm')}
                </div>
              </div>
            )}
            <div className="font-medium text-blackBase mb-[5px]">Комментарии психолога</div>
            {!editMode && <div className="text-greyBase mb-[20px]">{meetData.dpdg.comment}</div>}
            {editMode && (
              <div>
                <div>
                  <textarea
                    className="w-full min-h-[120px] border border-greyBase rounded-[10px] p-[13px_20px]"
                    placeholder="Введите текст"
                    value={commentsData.dpdg}
                    onChange={(e) =>
                      setCommentsData((prev) => {
                        return { ...prev, dpdg: e.target.value };
                      })
                    }
                  />
                </div>
              </div>
            )}
          </>
        )}
        {meetData?.questions && (
          <div className="mb-[20px]">
            <h2 className="font-comfortaa font-semibold text-lg lg:text-xl text-blackBase mb-[10px]">
              Используемые вопросы
            </h2>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-[20px] gap-y-[10px]">
              {meetData?.questions.map((el, idx) => (
                <div className="flex gap-[10px]" key={idx}>
                  {/* <span className="text-blackBase text-sm">{`${idx + 1}.`}</span> */}
                  <span className="text-blackBase text-sm grow">{el.question}</span>
                  <span className="text-greyBase04 text-sm">
                    {dayjs(el?.date_time).format('HH:mm')}
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      <div>
        <h2 className="flex items-center gap-[5px] font-comfortaa font-semibold text-lg lg:text-xl text-blackBase mb-[10px]">
          <span>Заключение по сессии</span>
          <span onClick={() => setModalConclusion(true)}>
            <QuestionsCircleIcon className="cursor-pointer" />
          </span>
        </h2>

        {!editMode && <div className="text-greyBase">{meetData?.result}</div>}
        {editMode && (
          <div>
            <div>
              <textarea
                value={commentsData.result}
                onChange={(e) =>
                  setCommentsData((prev) => {
                    return { ...prev, result: e.target.value };
                  })
                }
                className="w-full min-h-[120px] border border-greyBase rounded-[10px] p-[13px_20px]"
                placeholder="Введите текст, например, тип клиента, запрос, рабочие гипотезы, техники, методы, интервенция, что клиент “забрал” и т.п."
              />
            </div>
          </div>
        )}
      </div>

      {editMode && (
        <div className="flex justify-end mt-[40px]">
          <Button
            type="black"
            className="w-full md:w-max !p-[6px_30px] text-sm md:!text-base"
            onClick={() => handleSave()}>
            Сохранить
          </Button>
        </div>
      )}

      <ModalWrap isShow={modalConclusion}>
        <div className="w-[980px] max-w-[90vw] rounded-[20px] lg:rounded-[40px] p-5 lg:p-8 bg-white relative">
          <div
            className="absolute top-[40px] right-[40px]"
            onClick={() => setModalConclusion(false)}>
            <CloseCrossBtn />
          </div>
          <h2 className="font-comfortaa font-bold text-base lg:text-2xl mb-[20px]">
            Возможная структура заключения
          </h2>

          <ul className="list-decimal text-sm lg:text-lg text-blackBase">
            <li>Тип клиента</li>
            <li>Наличие психиатрического диагноза</li>
            <li>Фармакотерапия (какие препараты)</li>
            <li>Жалоба \ проблема \ запрос</li>
            <li>Рабочая гипотеза</li>
            <li>Протективные факторы Клиента</li>
            <li>Установки Клиента (если есть)</li>
            <li>Методы, которые использовались на сессии</li>
            <li>
              Техники и интервенции, которые использовались (например: сократический диалог,
              модуляция установок, дерефлексия, парадоксальная интенция, самораскрытие, отражение,
              конфронтация, прямое руководство, поддержка, информирование, библиотерапия, метод
              «Горячего стула» и т.п.)
            </li>
            <li>План дальнейшей работы</li>
          </ul>
        </div>
      </ModalWrap>

      <ModalWrap isShow={modalWithClient}>
        <div className="w-[980px] max-w-[90vw] rounded-[20px] lg:rounded-[40px] p-5 lg:p-8 bg-white relative">
          <div
            className="absolute top-[20px] lg:top-[40px] right-[20px] lg:right-[40px]"
            onClick={() => setModalWithClient(false)}>
            <CloseCrossBtn />
          </div>
          <h2 className="font-comfortaa font-bold text-base lg:text-2xl mb-5 lg:mb-10 pr-5">
            {clientData?.full_name}
          </h2>

          <div className="flex flex-col md:flex-row items-start md:items-center justify-between gap-[5px] mb-5 lg:mb-10">
            <div className="flex gap-[15px]">
              <div>Количество записей в терапевтическом журнале:</div>
              <div>{consultations?.length}</div>
            </div>
            <Link
              to={'/journal'}
              className="self-end font-comfortaa font-semibold text-sm text-redBase1 hover:underline">
              Смотреть записи
            </Link>
          </div>

          <div className="grid grid-cols-6 gap-y-[10px] mb-5 lg:mb-10">
            <div className="flex gap-[15px] col-span-6 lg:col-span-2">
              <span className="text-xs md:text-base text-greyBase">Дата рождения:</span>
              <span className="text-xs md:text-base text-blackBase">
                {dayjs(clientData?.birthday).format('DD.MM.YYYY')}
              </span>
            </div>
            <div className="flex gap-[15px] col-span-6 lg:col-span-2">
              <span className="text-xs md:text-base text-greyBase">Часовой пояс:</span>
              <span className="text-xs md:text-base text-blackBase">{clientData?.time_zone}</span>
            </div>
            <div className="flex gap-[15px] col-span-6 lg:col-span-2">
              <span className="text-xs md:text-base text-greyBase">Телефон:</span>
              <span className="text-xs md:text-base text-blackBase">{clientData?.phone}</span>
            </div>
            <div className="flex gap-[15px] col-span-6 lg:col-span-2">
              <span className="text-xs md:text-base text-greyBase">Почта:</span>
              <span className="text-xs md:text-base text-blackBase">{clientData?.email}</span>
            </div>
            <div className="flex gap-[15px] col-span-6 lg:col-span-2">
              <span className="text-xs md:text-base text-greyBase">Telegram:</span>
              <span className="text-xs md:text-base text-blackBase">{clientData?.telegram}</span>
            </div>
            <div className="flex gap-[15px] col-span-6 lg:col-span-2">
              <span className="text-xs md:text-base text-greyBase">VK:</span>
              <span className="text-xs md:text-base text-blackBase">{clientData?.vk}</span>
            </div>
          </div>

          <div className="flex gap-[15px] mb-5 lg:mb-10 flex flex-col md:flex-row">
            <div className="md:w-[132px] text-xs md:text-base text-greyBase">
              Дополнительный комментарий:
            </div>
            <p className="text-xs md:text-base text-blackBase">{clientData?.note}</p>
          </div>

          <div className="mb-5 lg:mb-10">
            Клиент {clientData?.is_active ? 'Активный' : 'Неактивный'}
          </div>

          <div className="flex justify-end">
            <Button
              type="black"
              className="w-full md:w-max !p-[6px_30px] text-sm md:!text-base"
              onClick={() => setiIsEditClient(true)}>
              Изменить
            </Button>
          </div>
        </div>
      </ModalWrap>

      <CreateClientForm
        open={isEditClient}
        close={() => setiIsEditClient(false)}
        save={(form) => handleSaveClient(form)}
        clientData={clientData}
      />
    </div>
  );
};

export default JournalCard;
