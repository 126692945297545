import React, { FC, useCallback, useEffect, useState } from 'react'

interface IProps {
  minutes: number
  seconds: number
  onRefreshAuthCode: () => void
}

const Timer: FC<IProps> = ({minutes = 1, seconds = 0, onRefreshAuthCode}) => {
  const [timer, setTimer] = useState({minutes: minutes, seconds: seconds})
  const stopTime = () => Date.now() + (minutes * 60 * 1000) + (seconds * 1000) + 1000

  const getTimeRemaining = (endTime: number) => {
    const time = endTime - Date.now()
    const data = new Date(time)
    if (time <= 0) {
      return
    }

    setTimer({
      minutes: data.getMinutes(),
      seconds: data.getSeconds()
    })
  }

  useEffect(() => {
    const endTime = stopTime()
    let timerId = setInterval(() => getTimeRemaining(endTime), 1000)

    return () => clearInterval(timerId)
    // eslint-disable-next-line
  }, [])

  const setNewTimer = useCallback(() => {
    const endTime = stopTime()
    let timerId = setInterval(() => getTimeRemaining(endTime), 1000)

    return () => clearInterval(timerId)
    // eslint-disable-next-line
  }, [timer])

  const getCode = async () => {
    await onRefreshAuthCode()
    setTimer({
      minutes: minutes,
      seconds: seconds
    })
    setNewTimer()
  }

  return (
    <div className="flex flex-col items-center">
      <div className="flex justify-center gap-[5px] text-xs md:text-sm text-greyBase mb-1">
        Вы можете запросить повторно код через <span
        className="w-[50px]">{timer.minutes < 10 ? `0${timer.minutes}` : timer.minutes}:{timer.seconds < 10 ? `0${timer.seconds}` : timer.seconds}</span>
      </div>
      {
        (timer.minutes === 0 && timer.seconds === 0) && (
          <button className="text-center sm:px-[30px] text-sm md:text-base text-redBase border-none bg-transparent"
                  onClick={getCode}>
            Запросить код повторно
          </button>
        )
      }
    </div>
  )
}

export default Timer