import React, { useEffect, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { SmileIcon } from '../components/icons/smile';
import { QuestionsCircleIcon } from '../components/icons/question-circle';
import { DpdgCircleIcon } from '../components/icons/dpdg-circle';
import { SquaresIcon } from '../components/icons/squares';
import FlowerIcon from '../components/icons/flower';
import Button from '../components/common/button';
import ToolsCard from '../components/common/tools-card';
import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import { selectUser, setUserData } from '../store/auth.slice';
import { useChangeUserMutation } from '../services/users.api';
import { toast } from 'react-toastify';

const Tools = () => {
  const psyTools = useAppSelector((state) => state.psyToolsSlice.tools);
  const params = useLocation();
  const userData = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const [changeUser] = useChangeUserMutation();
  const [voteArr, setvoteArr] = useState<string[]>([]);

  useEffect(() => {
    if (!userData) return;
    if (userData.voted_psy_tools.length > 0 && voteArr.length === 0) {
      setvoteArr(userData.voted_psy_tools);
    }
  }, [userData]);

  const handleSave = async () => {
    try {
      if (!userData) return;

      dispatch(setUserData({ ...userData, voted_psy_tools: voteArr }));
      await changeUser({
        id: userData.id,
        voted_psy_tools: voteArr,
      });

      toast.success('Данные успешно сохранены');
    } catch (error) {
      toast.error('Произошла ошибка');
    }
  };

  return (
    <div>
      {params.pathname.split('/').length === 2 && (
        <>
          <h2 className="font-comfortaa font-semibold text-lg sm:text-2xl text-blackBase mb-5">
            Инструменты видеокабинета ZiNGiZi{' '}
          </h2>
          <p className="text-sm text-greyBase mb-5">
            Видеокабинет ZiNGiZi содержит три колоды метафорических карт; инструменты «Детектор
            эмоций», «Подкинь вопрос» и «ДПДГ онлайн».
          </p>

          <div className="flex flex-wrap gap-[49px_26px] mb-5">
            <Link to={'emotion-detector'} className="w-[150px]">
              <div className="w-full h-[180px] flex items-center justify-center bg-goldBase mb-[15px] rounded-[10px]">
                <SmileIcon fill="#C00000" opacity="1" />
              </div>
              <p className="text-[13px] text-center text-greyBase">Детектор эмоций</p>
            </Link>
            <Link to={'metaphorical-cards'} className="w-[150px]">
              <div className="w-full h-[180px] flex items-center justify-center bg-goldBase mb-[15px] rounded-[10px]">
                <FlowerIcon fill="#C00000" />
              </div>
              <p className="text-[13px] text-center text-greyBase">Метафорические карты</p>
            </Link>
            <Link to={'question'} className="w-[150px]">
              <div className="w-full h-[180px] flex items-center justify-center bg-goldBase mb-[15px] rounded-[10px]">
                <QuestionsCircleIcon fill="#C00000" width={52} height={52} needHover={false} />
              </div>
              <p className="text-[13px] text-center text-greyBase">Подкинь вопрос</p>
            </Link>
            <Link to={'dpdg'} className="w-[150px]">
              <div className="w-full h-[180px] flex items-center justify-center bg-goldBase mb-[15px] rounded-[10px]">
                <DpdgCircleIcon fill="#C00000" opacity="1" fillBg="#DEC05E" />
              </div>
              <p className="text-[13px] text-center text-greyBase">ДПДГ онлайн</p>
            </Link>
          </div>

          <p className="text-sm text-greyBase mb-[10px]">
            Библиотека инструментов постоянно пополняется.
          </p>
          <div className="flex flex-wrap gap-[49px_26px] mb-5">
            <div className="w-[150px]">
              <div className="w-full h-[180px] flex items-center justify-center bg-greyBase mb-[15px] rounded-[10px] relative">
                <div className="w-full absolute top-0 left-0 p-[5px] bg-goldBase font-medium text-xs text-blackBase rounded-t-[10px] text-center">
                  Скоро релиз
                </div>
                <SquaresIcon needHover={false} />
              </div>
              <p className="text-[13px] text-center text-greyBase">
                Техника
                <br /> холотропного дыхания
              </p>
            </div>
            <div className="w-[150px]">
              <div className="w-full h-[180px] flex items-center justify-center bg-greyBase mb-[15px] rounded-[10px] relative">
                <div className="w-full absolute top-0 left-0 p-[5px] bg-goldBase font-medium text-xs text-blackBase rounded-t-[10px] text-center">
                  Скоро релиз
                </div>
                <SquaresIcon needHover={false} />
              </div>
              <p className="text-[13px] text-center text-greyBase">Техника мышечной релаксации</p>
            </div>
            <div className="w-[150px]">
              <div className="w-full h-[180px] flex items-center justify-center bg-greyBase mb-[15px] rounded-[10px] relative">
                <div className="w-full absolute top-0 left-0 p-[5px] bg-goldBase font-medium text-xs text-blackBase rounded-t-[10px] text-center">
                  Скоро релиз
                </div>
                <SquaresIcon needHover={false} />
              </div>
              <p className="text-[13px] text-center text-greyBase">
                Техника
                <br /> модуляции установок
              </p>
            </div>
          </div>

          <p className="font-semibold text-sm text-redBase mb-[10px]">
            Проголосуйте за инструменты, которые вы хотите видеть на сервисе ZiNGiZi
          </p>
          <p className="text-sm text-greyBase mb-[10px]">
            Пожалуйста, проголосуйте и помогите определить, какие инструменты для вас наиболее
            важны. Отметьте все те, которые вы ждете с нетерпением и хотели бы использовать в своей
            практике.
          </p>
          <div className="flex flex-wrap gap-[49px_26px] pb-[20px]">
            {psyTools
              .filter((el) => el.status === 'in_plan')
              .map((item, idx) => (
                <div key={idx} className="w-[150px]">
                  <ToolsCard
                    id={item.id}
                    selected={voteArr}
                    setSelected={setvoteArr}
                    text={item.description}
                    cardName={item.label}
                    isBlocked={
                      userData?.voted_psy_tools ? userData?.voted_psy_tools?.length > 0 : false
                    }
                  />
                </div>
              ))}
          </div>
          <Button
            onClick={() => handleSave()}
            type="primary"
            disabled={userData?.voted_psy_tools ? userData?.voted_psy_tools?.length > 0 : false}
            className={classNames('!text-base !font-semibold !p-[6px] mb-[80px]', {
              ['!bg-greyBase !border-greyBase !text-white']: userData?.voted_psy_tools
                ? userData?.voted_psy_tools?.length > 0
                : false,
            })}>
            Проголосовать
          </Button>
        </>
      )}

      <Outlet />
    </div>
  );
};

export default Tools;
