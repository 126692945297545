import React, { FC } from 'react'
import cx from 'classnames'

interface IProps {
  type?: 'primary' | 'bordered' | 'black' | 'grey' | 'link'
  className?: string
  disabled?: boolean
  children?: React.ReactNode
  onClick?: () => void
  onMouseMove?:() => void
  onMouseLeave?: () => void
}

const Button: FC<IProps> = ({
                              type = 'primary',
                              className,
                              disabled,
                              children,
                              onClick,
                              onMouseMove,
                              onMouseLeave,
                            }) => {
  return (
    <button
      className={cx('w-full flex justify-center p-[15px] md:text-xl font-comfortaa font-bold rounded-[10px] border-2 border-transparent',
        className,
        {
          'text-white bg-redBase border-redBase hover:border-redBaseWithBlur20 hover:bg-redBaseWithBlur20 active:border-blackBase active:bg-redBase': type === 'primary',
          'text-redBase !border-redBase bg-transparent hover:bg-redBase hover:text-white': type === 'bordered',
          'text-white bg-blackBase hover:text-redBase border-blackBase hover:border-blackBase active:border-redBase active:text-white': type === 'black',
          'text-greyBlue bg-greyLightBlueLight border-greyLightBlueLight hover:bg-blackBase hover:border-blackBase hover:text-white active:text-greyBlue active:border-blackBase active:bg-greyLightBlueLight': type === 'grey',
          '!w-max bg-transparent !text-redBase1 hover:underline !p-0 !text-sm font-semibold': type === 'link',
          'opacity-50 !cursor-default pointer-events-none': disabled,
        })}
      onClick={onClick}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </button>
  )
}

export default Button