import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useVideoRoom } from '../../hooks/useVideoRoom';
import { RemoteVideo } from '../remote-videos';

const LinkRoom = () => {
  const params = useParams();
  const [videoRoom, joined, remoteVideos]: any = useVideoRoom();
  const navigate = useNavigate();
  const [copiedLink, setCopiedLink] = useState(false);
  const [isAudioMuted, setIsAudioMuted] = useState(false);
  const [isVideoMuted, setIsVideoMuted] = useState(false);

  const countRenderRef = useRef<any>(0);
  countRenderRef.current++;
  console.log('render', countRenderRef.current);

  const videoRef = useRef<any>();

  const roomName = params.id;

  useEffect(() => {
    if (copiedLink) {
      const t = setTimeout(() => setCopiedLink(false), 1000);
      return () => clearTimeout(t);
    }
  }, [copiedLink]);

  useEffect(() => {
    videoRef?.current?.setAttribute('playsinline', '');
  }, []);

  return (
    <div>
      <h2>Комната по ссылке</h2>
      <p>
        {joined ? `Successfully joined room ${roomName}` : roomName ? `room ${roomName}` : null}
      </p>
      {roomName && (
        <div>
          <p>
            {window.location.href}
            <button
              onClick={() => {
                navigator.clipboard.writeText(window.location.href);
                setCopiedLink(true);
              }}>
              {copiedLink ? 'скопировано' : 'копировать ссылку'}
            </button>
          </p>
        </div>
      )}
      <div>
        {roomName && videoRoom && joined === false && (
          <button
            onClick={() => {
              params.id == null && navigate(`/test/${roomName}`);
              videoRoom.joinRoom(roomName);
            }}>
            Подключиться к комнате
          </button>
        )}
      </div>
      <div>
        <div>
          {roomName && (
            <video id="localvideo" ref={videoRef} width="320" height="240" autoPlay muted></video>
          )}
        </div>
        <div>
          <button
            onClick={() => {
              setIsVideoMuted(!videoRoom.toggleVideo());
            }}>
            {isVideoMuted ? 'Включить видео' : 'Выключить видео'}
          </button>
          <button
            onClick={() => {
              setIsAudioMuted(!videoRoom.toggleAudio());
            }}>
            {isAudioMuted ? 'Включить аудио' : 'Выключить аудио'}
          </button>
        </div>
        {joined && (
          <button
            onClick={() => {
              videoRoom.leaveRoom();
            }}>
            Отключиться
          </button>
        )}
        {joined && (
          <button
            onClick={() => {
              videoRoom.destroyRoom();
            }}>
            Удалить комнату
          </button>
        )}
      </div>
      <div>
        {remoteVideos.map((x: { display: any; attachVideo: any }) => (
          <RemoteVideo key={x.display} fn={x.attachVideo} />
        ))}
      </div>
    </div>
  );
};

export default LinkRoom;
